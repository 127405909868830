export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: 'Phân công cho nhóm',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: 'Phân công cho nhân viên',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: 'Gắn thẻ',
    inputType: 'multi_select',
  },
  {
    key: 'remove_label',
    label: 'Gỡ thẻ',
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: 'Gỡ nhóm được phân công',
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: 'Gửi bản ghi đến email',
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: 'Tắt tiếng thông báo',
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: 'Tạm dừng thông báo',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: 'Đánh dấu đã giải quyết',
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: 'Gửi tệp đính kèm',
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: 'Gửi tin nhắn',
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: 'Thêm ghi chú riêng',
    inputType: 'textarea',
  },
  {
    key: 'change_priority',
    label: 'Thay đổi độ ưu tiên',
    inputType: 'search_select',
  },
];
