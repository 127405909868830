import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import GalleyryImageAPI from '../../api/galleryImage';

const state = {
  records: [],
  imageList: [],
};

export const getters = {
  allFolder($state) {
    return $state.records.map(i => ({ ...i, isEdit: false }))
  },
  imageList($state) {
    return $state.imageList
  },
  // imageSelected($state) {
  //   console.log('$state.imageList.filter((item) => item.active);', $state.imageList.filter((item) => item.active));
  //   return $state.imageList.filter((item) => item.active);
  // }
};

export const actions = {
  getFolder: async ({ commit }) => {
    try {
      const response = await GalleyryImageAPI.getAllFolder();
      commit(types.GET_FOLDER_IMAGE, response.data);
    } catch (error) {
    }
  },

  addFolder: async ({ commit }, obj) => {
    try {
      const response = await GalleyryImageAPI.addFolder(obj);
      commit(types.ADD_FOLDER_IMAGE, response.data);
    } catch (error) {
      // throw new Error(errorMessage);
    } finally {
    }
  },

  updateFolder: async ({ commit }, obj) => {
    try {
      const response = await GalleyryImageAPI.updateFolder(obj.id, { name: obj.name });
      commit(types.UPDATE_FOLDER_IMAGE, response.data);
    } catch (error) {
      // throw new Error(errorMessage);
    } finally {
    }
  },

  deleteFolder: async ({ commit }, id) => {
    try {
      await GalleyryImageAPI.deleteFolder(id);
      commit(types.DELETE_FOLDER_IMAGE, id);
    } catch (error) {
      // throw new Error(errorMessage);
    } finally {
    }
  },

  getImageByFolder: async ({ commit }, params) => {
    try {
      const response = await GalleyryImageAPI.getImageByFolder(params);
      commit(types.GET_IMAGE_BY_FOLDER, response.data.payload);
    } catch (error) {
    }
  },

  uploadImage: async ({ commit }, data) => {
    try {
      const response = await GalleyryImageAPI.uploadImage(data);
      commit(types.UPLOAD_NEW_IMAGE, response.data);
    } catch (error) {
    }
  },

  selectImage: ({ commit }, id) => {
    commit(types.SELECT_IMAGE, id);
  },

  deselectAll: ({ commit }) => {
    commit(types.DESELECT_ALL);
  },

  deleteImage: async ({ commit }, id) => {
    try {
      await GalleyryImageAPI.deleteImage(id);
      commit(types.DELETE_IMAGE, id);
    } catch (error) {
      // throw new Error(errorMessage);
    } finally {
    }
  },
};

export const mutations = {
  [types.GET_FOLDER_IMAGE]: MutationHelpers.set,
  [types.ADD_FOLDER_IMAGE]: MutationHelpers.create,
  [types.UPDATE_FOLDER_IMAGE]: MutationHelpers.update,
  [types.DELETE_FOLDER_IMAGE]: MutationHelpers.destroy,

  [types.GET_IMAGE_BY_FOLDER]($state, data) {
    $state.imageList = data
  },

  [types.UPLOAD_NEW_IMAGE]($state, data) {
    $state.imageList.push(data)
  },

  [types.SELECT_IMAGE]($state, id) {
    let indexItem = $state.imageList.findIndex(img => img.id === id);
    if (indexItem >= 0) {
      $state.imageList[indexItem].active = !$state.imageList[indexItem].active
    }
  },
  [types.DESELECT_ALL]($state) {
    $state.imageList = $state.imageList.map(item => {
      let data = item
      data.active = false
      return data
    })
  },

  [types.DELETE_IMAGE]($state, id) {
    let indexItem = $state.imageList.findIndex(img => img.id === id);
    if (indexItem >= 0) {
      $state.imageList.splice(indexItem, 1)
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
