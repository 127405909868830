<template>
  <modal :show.sync="isOpen" :on-close="onToggle" size="!w-8/12 !h-5/6">
    <div class="w-full h-full flex mx-auto">
      <div v-if="isOpenMenu" class="flex flex-col py-5 px-3 w-1/3 border-r dark:bg-slate-900 border-slate-700 relative">
        <div class="space-y-3 flex-1 overflow-hidden">
          <div class="flex px-5 items-center justify-between">
            <h2>{{ $t('GALLERY.FOLDER') }}</h2>
          </div>

          <div @click="loadDataRecenly()" :class="{
            'dark:bg-slate-800 text-woot-500 py-2': activeFolder == 'recently',
            'cursor-pointer': true
          }">
            <div class="flex px-5 items-center space-x-3">
              <fluent-icon class="flex-shrink-0 icon" icon="history" size="16" />
              <span>{{ $t('GALLERY.RECENTLY') }}</span>
            </div>
          </div>

          <div class="border-b dark:bg-slate-900 border-slate-700"></div>
          <div class="folder-list overflow-y-auto">
            <ul class="pt-2 pb-4 space-y-4 text-sm list-none ml-0">
              <!-- <li>
                <div class="flex px-5 items-center space-x-3">
                  <fluent-icon
                    class="flex-shrink-0 icon"
                    icon="heart"
                    size="16"
                  />
                  <span>Favorite</span>
                </div>
              </li> -->

              <li v-for="item in listAllFolder" :key="item.id" @click="loadData(item.id)" :class="{
                'dark:bg-slate-800 text-woot-500': activeFolder == item.id && !item.isEdit,
                'cursor-pointer': true
              }">
                <template v-if="!item.isEdit">
                  <div class="flex pl-5 items-center justify-between">
                    <div class="flex items-center folder-name-block">
                      <fluent-icon class="flex-shrink-0 icon" icon="folder" size="16" />
                      <span class="text-ellipsis pl-3">{{ item.name }}</span>
                    </div>

                    <div>
                      <woot-button icon="signature" color-scheme="secondary" variant="clear" size="small" @click="onOpenEditFolder(item)" />
                      <woot-button icon="delete" color-scheme="secondary" variant="clear" size="small" @click.stop="onDeleteFolder(item)" />
                    </div>
                  </div>
                </template>

                <template v-else>
                  <input v-model="folderSelected.name" type="search" name="Search"
                    class="w-full py-0 text-sm focus:bg-transparent focus:outline-none mb-1" />

                  <div class="text-right">
                    <woot-button icon="save" color-scheme="secondary" variant="clear" :disabled="!folderSelected.name" size="small"
                      @click="onSaveFolder" />
                    <woot-button icon="dismiss" color-scheme="secondary" variant="clear" @click="item.isEdit = false" size="small" />
                  </div>
                </template>
              </li>
            </ul>
          </div>
          <div class="border-b dark:bg-slate-900 border-slate-700"></div>

          <div @click="onNewFolder()" class="cursor-pointer">
              <div v-show="!isAddFolder" class="flex pl-5 items-center space-x-3">
                <fluent-icon class="flex-shrink-0 icon" icon="folder-add" size="16" />
                <span>{{ $t('GALLERY.ADD_FOLDER') }}</span>
              </div>
            </div>

            <div v-show="isAddFolder">
              <input v-model="folderName" type="search" name="folderName" ref="folderName" class="w-full py-0 text-sm focus:bg-transparent focus:outline-none mb-1" />

              <div class="text-right">
                <woot-button icon="save" color-scheme="secondary" variant="clear" :disabled="!folderName" @click="onAddFolder" size="small" />
                <woot-button icon="dismiss" color-scheme="secondary" variant="clear" @click="isAddFolder = false" size="small" />
              </div>
            </div>
        </div>
      </div>

      <div class="relative w-full">
        <div>
          <woot-button variant="smooth" size="tiny" color-scheme="secondary"
            class="rounded-bl-calc rtl:rotate-180 rounded-tl-calc fixed top-4 md:top-[5.25rem] z-10 bg-white dark:bg-slate-700 border-slate-50 dark:border-slate-600 border-solid border border-l-0 box-border"
            :icon="isOpenMenu ? 'arrow-chevron-left' : 'arrow-chevron-right'" @click="openMenu" />
        </div>
        <div class="relative mt-10 px-5 overflow-y-auto gallery-grid">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4 gallery-grid" style="max-height: 522px">
            <div v-for="item in listImage" :key="item.id" class="h-32 rounded-lg cursor-pointer" :class="{
              'border-solid border-2 border-woot-500': item.active,
            }" @click="onSelectImg(item)">
              <img class="h-full max-w-full rounded-lg object-cover" :src="item.file_url" alt="" />
            </div>
          </div>
        </div>
        <div class="absolute w-full bottom-0 flex items-end justify-between border-t border-slate-700 p-5">
          <div>
            <input class="input-file__input" ref="file" type="file" multiple accept="image/*" @change="onUploadImage"
              style="visibility: hidden; display: none" />
            <woot-button size="small" @click="onOpenFile()">
              {{ $t('GALLERY.ADD_IMAGE') }}
            </woot-button>
          </div>

          <div>
            <template v-if="isShowBtnDeselectAndDelete">
              <woot-button @click="deselectAll" size="small" color-scheme="secondary">
                {{ $t('GALLERY.DESELECT_ALL') }}
              </woot-button>
              <woot-button @click="deleteImage" size="small" color-scheme="alert">
                {{ $t('GALLERY.DELETE') }}
              </woot-button>
            </template>

            <woot-button @click="selectAll" size="small" color-scheme="secondary">
              {{ $t('GALLERY.SELECT_ALL') }}
            </woot-button>

            <woot-button size="small" @click="renderImageToBinary" :disabled="!imgListSelect.length">
              {{ imgListSelect.length > 0 ? imgListSelect.length : '' }} {{ $t('GALLERY.SELECT') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../Modal.vue';
import { mapGetters } from 'vuex';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_IMAGE_UPLOAD_SIZE } from 'shared/constants/messages';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'GalleryImage',
  components: { Modal },
  mixins: [ alertMixin ],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showGlary: false,
      folderName: '',
      activeFolder: 'recently',
      isAddFolder: false,
      isOpenMenu: true,
      imgListSelect: [],
      folderSelected: {}
    };
  },

  mounted() {
    this.$store.dispatch('galleryImage/getFolder');
    this.loadDataRecenly();
  },

  computed: {
    ...mapGetters({
      listAllFolder: 'galleryImage/allFolder',
      listImage: 'galleryImage/imageList',
      // imgListSelect: 'galleryImage/imageSelected',
    }),
    isShowBtnDeselectAndDelete: function () {
      if (this.imgListSelect.length) {
        const ids = [...new Set(this.imgListSelect.map(i => i.gallery_folder_id))]
        return this.activeFolder === 'recently' || ids.includes(this.activeFolder)
      }

      return false
    }
  },

  methods: {
    openMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    async loadDataRecenly() {
      this.activeFolder = 'recently'
      await this.$store.dispatch('galleryImage/getImageByFolder');
      this.handleActiveImage()
    },
    onSelectImg(item) {
      let indexItem = this.imgListSelect.findIndex(img => img.id === item.id);
      if (indexItem >= 0) {
        this.imgListSelect.splice(indexItem, 1)
      } else {
        this.imgListSelect.push(item)
      }
      this.$store.dispatch('galleryImage/selectImage', item.id);
    },

    onAddFolder() {
      this.$store.dispatch('galleryImage/addFolder', { name: this.folderName });
      this.folderName = null;
      this.isAddFolder = false;
    },

    deselectAll() {
      this.imgListSelect = [];
      this.$store.dispatch('galleryImage/deselectAll');
    },

    selectAll() {
      this.listImage.forEach(item => {
        let indexItem = this.imgListSelect.findIndex(img => img.id === item.id)
        if (indexItem < 0) {
          this.imgListSelect.push(item)
          this.$store.dispatch('galleryImage/selectImage', item.id)
        }
      })
    },

    onToggle() {
      this.$emit('update:isOpen', !this.isOpen);
    },

    onOpenFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },

    onNewFolder() {
      this.isAddFolder = true;
      this.$nextTick(() => {
        this.$refs.folderName.focus();
      })
    },

    async loadData(id) {
      if (Number(id) === Number(this.activeFolder)) {
        return
      }
      this.activeFolder = id;
      await this.$store.dispatch('galleryImage/getImageByFolder', {
        gallery_folder_id: id,
        is_favorite: false,
      });

      this.handleActiveImage()
    },

    handleActiveImage () {
      if (this.imgListSelect.length) {
        this.listImage.forEach(item => {
          const idx = this.imgListSelect.findIndex(i => i.id === item.id)
          if (idx !== -1) {
            this.$store.dispatch('galleryImage/selectImage', item.id)
          }
        })
        this.$forceUpdate()
      }
    },

    deleteImage() {
      this.imgListSelect.forEach(image =>
        this.$store.dispatch('galleryImage/deleteImage', image.id)
      );
      this.imgListSelect = [];
    },

    onUploadImage(event) {
      let files = event.target?.files;
      let hasLargeFile = false
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        if (!file.type.startsWith('image/')) continue;
        if (!checkFileSizeLimit(file, MAXIMUM_IMAGE_UPLOAD_SIZE)) {
          hasLargeFile = true
          continue
        };

        this.$store.dispatch('galleryImage/uploadImage', {
          file: file,
          folder: this.activeFolder,
        });
      }
      this.$refs.file.value = ''

      if (hasLargeFile) {
        this.showAlert(this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
          MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE: MAXIMUM_IMAGE_UPLOAD_SIZE,
        }));
      }
    },

    closeModal() { },

    renderImageToBinary() {
      this.imgListSelect.forEach(image =>
        this.start(image.file_url)
      );
      this.onToggle()
    },

    start(url) {
      fetch(url)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .then(dataUrl => {
          var fileData = this.dataURLtoFile(dataUrl, url);
          this.$emit('onSendImage', {
            file: fileData,
            preview: url,
            type: 'image',
          });
        });
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    onOpenEditFolder(folder) {
      folder.isEdit = true
      this.folderSelected = structuredClone(folder);
    },

    onSaveFolder() {
      this.$store.dispatch('galleryImage/updateFolder', this.folderSelected)
    },

    onDeleteFolder(folder) {
      this.$store.dispatch('galleryImage/deleteFolder', folder.id)
    }
  },
};
</script>

<style scoped>
@tailwind components;

@layer components {
  .rounded-bl-calc {
    border-bottom-right-radius: calc(1.5rem + 1px);
  }

  .rounded-tl-calc {
    border-top-right-radius: calc(1.5rem + 1px);
  }
}
</style>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.size-modal {
  width: 70%;
}

.folder {
  border-radius: 8px;
  border: solid 1px #0d0a0a;
  padding: 8px;
  width: 50%;
  margin-bottom: 8px;
}

.folder-name-block {
  max-width: calc(100% - 70px);
}

.active-folder {
  background: #eee;
  border-color: red;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.folder-list {
  max-height: calc(100% - 245px);
}

.galley-grid {
  max-height: calc(100% - 90px);
}
</style>
