<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />
      <form class="flex flex-col w-full">
        <div class="w-full">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              @blur="$v.content.$touch"
            />
          </div>
        </div>

        <div v-if="hasAttachments" class="attachment-preview-box">
          <attachment-preview
            class="flex-col mt-4"
            :attachments="attachedFiles"
            :remove-attachment="removeAttachment"
          />
        </div>

        <div>
          <woot-button
            class-names="button--upload"
            :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            icon="attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            type="button"
            @click="onSelectImages()"
          />
          <input
            ref="fileAddCanned"
            type="file"
            multiple
            style="visibility: hidden; display: none"
            @change="onUploadImages"
          />
          <!-- <woot-button icon="image" color-scheme="secondary" type="button"  /> -->
        </div>

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-submit-button
            :disabled="
              $v.content.$invalid ||
              $v.shortCode.$invalid ||
              editCanned.showLoading
            "
            type="button"
            @click="editCannedResponse()"
            :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
            :loading="editCanned.showLoading"
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import alertMixin from 'shared/mixins/alertMixin';
import Modal from '../../../../components/Modal.vue';
import { uploadFile } from 'dashboard/helper/uploadHelper';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
    AttachmentPreview,
  },
  mixins: [alertMixin],
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    onClose: { type: Function, default: () => {} },
    edAttachedFiles: { type: Array, default: [] },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
      },
      shortCode: this.edshortCode,
      content: this.edcontent,
      attachedFiles: this.edAttachedFiles,
      show: true,
      promiseImages: [],
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
    hasAttachments() {
      return !!this.attachedFiles.length;
    },
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    async editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls

      let resImages = [];
      const fileUploaded = this.attachedFiles
        .filter(file => file.blobSignedId)
        .map(item => item.blobSignedId);
      if (this.promiseImages.length > 0) {
        resImages = await Promise.all(this.promiseImages);
      }

      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          content: this.content,
          file_ids: fileUploaded.concat(resImages.map(i => i.blobId)),
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },

    onUploadImages({ target: { files = [] } }) {
      if (!files.length) {
        this.$refs.fileAddCanned.value = '';
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.promiseImages.push(uploadFile(file));

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.attachedFiles.push({
            currentChatId: 1,
            resource: file,
            isPrivate: true,
            thumb: reader.result,
            blobSignedId: null,
          });
        };
      }
      this.$refs.fileAddCanned.value = '';
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },

    onSelectImages() {
      this.$refs.fileAddCanned.click();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    @apply hidden;
  }

  .ProseMirror-woot-style {
    @apply min-h-[12.5rem];

    p {
      @apply text-base;
    }
  }
}
</style>
