/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }

  cannedResponsesUploadImages(data) {
    return axios.post(`${this.baseUrl()}/canned_responses`, data);
  }
}

export default new CannedResponse();
