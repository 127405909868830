/* global axios */
import ApiClient from './ApiClient';

class GalleyryImageAPI extends ApiClient {
  constructor() {
    super({ accountScoped: true });
  }

  getAllFolder() {
    return axios.get(`${this.baseUrl()}/gallery_folders`);
  }

  addFolder(data) {
    return axios.post(`${this.baseUrl()}/gallery_folders`, data);
  }

  updateFolder(id, data) {
    return axios.put(`${this.baseUrl()}/gallery_folders/${id}`, data);
  }

  deleteFolder(id) {
    return axios.delete(`${this.baseUrl()}/gallery_folders/${id}`);
  }

  getImageByFolder(params) {
    return axios.get(`${this.baseUrl()}/gallery_images`, {
      params: params
    })
  }

  uploadImage(data) {
    const formData = new FormData();
    formData.append('gallery_folder_id', data.folder);
    formData.append('file', data.file);
    return axios.post(`${this.baseUrl()}/gallery_images`, formData)
  }

  deleteImage(id) {
    return axios.delete(`${this.baseUrl()}/gallery_images/${id}`);
  }
}

export default new GalleyryImageAPI();
